import React, { useEffect, useContext,useState} from 'react';
import { Spinner} from "@blueprintjs/core";
import {CockpitContext} from '../../common/context/Context'
import FavouriteCard from './FavouriteCard'

function FavouritePanel(props){


    const [dashboardChart, setDashBoardChart] = useState()
    const [favItems, setFavItems] = useState(<Spinner className="chartmenu" size={25}/>)
    const cpContext = useContext(CockpitContext);

    useEffect(  () => {
        if(cpContext.userPrefs){
            if(cpContext.userPrefs.dashboard){
                setDashBoardChart(cpContext.userPrefs.dashboard)
            } 
            if(!cpContext.userPrefs.fav_charts || cpContext.userPrefs.fav_charts.length===0){
               setFavItems(<h6 className="cockpit-heading">No favourites added. You can favourite a chart when viewing by clicking the Star icon in the chart header</h6>)
            }else{
                var items = cpContext.userPrefs.fav_charts.map((fav) =>{
                    return <FavouriteCard dashboardChart={cpContext.userPrefs.dashboard} chartName={fav.chartName} chartURI={fav.chartURI} chartID={fav.chartID} workBookID={fav.workBookID} workBookURI={fav.workBookURI}></FavouriteCard>
               })
               setFavItems(items)
            }
        }
    },[cpContext.userPrefs,dashboardChart])



    

    return(
        <div>  
            <div className="dashboard-row cockpit-heading"> 
            <div className="dashboard-heading">Favourites</div>
            </div>
            <div className="dashboard-row "> 
                {favItems}
            </div>
        </div>



    )


}


export default FavouritePanel