import React from 'react';
import TableauReportDice from './Embed/TableauReportDice'
import ChartHeader from './ChartHeader'
import './Chart.css'
import {withAuthorisation} from '../auth/withAuthorisation'
import {withMixpanel} from '../mixpanel/withMixPanel'
import {TABLEAU_BASE_URL} from '../constants/routes'
class Chart extends React.Component {

   

    url = TABLEAU_BASE_URL + this.props.workBookURI + "/" + this.props.chartURI

    componentDidMount(){
        const {workBookURI, chartURI} = this.props;
        const down = <ChartHeader chartURL={this.url} chartID={this.props.chartID} workBookID={this.props.workBookID}  workBookURI={this.props.workBookURI} chartURI={this.props.chartURI} chartName={this.props.chartName}></ChartHeader>
        this.props.mixpanel.track('Chart View', {'workbookURI': workBookURI, 'chartURI': chartURI})
        this.props.setHeaderItem(down)
    }


    componentWillUnmount(){
        this.props.removeHeaderItem()
    }


    render() {

        const parameters = {
            tabs: 'No',
            hideTabs: true,
            hideToolbar: true,
            showAppBanner: false,
            display_count: 'n'
        };

      
        return (
            <TableauReportDice containerClass="chart" parameters={parameters} url={this.url} />
           
        )   
    
    }



}



export default withMixpanel(withAuthorisation(Chart));