import {tokenRequest,graphRequest} from './msal/config'

export function getTokens(authContext){
    return Promise.all([getToken(authContext, tokenRequest.scopes),getToken(authContext, graphRequest.scopes)])    
}


export function getToken(authContext, scopes){
    return authContext.instance.acquireTokenSilent({
        scopes: scopes,
        account: authContext.accounts[0]})
}