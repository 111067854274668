import React,{useCallback,useState,useContext, useEffect} from 'react';
import {
    Alignment,
    Button,
    Classes,
    Navbar,
    NavbarDivider,
    NavbarHeading,
    Popover,
    MenuItem,
    Menu,
    Position
} from "@blueprintjs/core";
import {useMsal} from "@azure/msal-react";

import {CockpitContext} from '../common/context/Context'
import {tokenRequest} from '../auth/msal/config'
import {getToken} from '../auth/TokenManager'
import {addFavourite,deleteFavourite} from '../common/favourites/FavouriteManager'
import './Chart.css'

const ChartHeader = (props) => {


    const setFav = <Button  icon="star-empty" minimal="true" onClick={() => favourite()}></Button>
    const unFav = <Button  icon="star" minimal="true" onClick={() => removeFavourite()}></Button>
    
    const [button,setButton] = useState(setFav)

    const authContext = useMsal();
    const cpContext = useContext(CockpitContext);


    useEffect( () => {
        
        if(cpContext.userPrefs && cpContext.userPrefs.fav_charts){
            const fav_charts = cpContext.userPrefs.fav_charts
            // search the array and see if this chart is a fav....
            // fav_charts.contains()
            if (fav_charts.some(fav => fav.chartURI === props.chartURI)) {
                    setButton(unFav)
            }else{
                    setButton(setFav)
            }
         }
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[cpContext.userPrefs,props.chartURI])


    const download = useCallback((action) => {
        var url = props.chartURL + "." + action
        window.open(url, '_blank');
    },[props.chartURL]);

    const favourite = useCallback((action) => {
        setButton(unFav)
        getToken(authContext,tokenRequest.scopes).then( result => {
            addFavourite(result.accessToken, props.workBookURI,props.workBookID,props.chartURI,props.chartName,props.chartID) 
            .then(data => {
                cpContext.userPrefs.fav_charts.push(data.fav)
            })
            .catch( error => console.log("Could not get data " + error.message));       
        })
 // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[unFav,props.chartName,props.chartURI,props.workBookURI]);

    const removeFavourite = useCallback((action) => {
        setButton(setFav)
        getToken(authContext,tokenRequest.scopes).then( result => {
            var fav = {"chartURI" : props.chartURI}
            deleteFavourite(result.accessToken,fav)
            .then(data => {
                const arr = cpContext.userPrefs.fav_charts.filter(item => item.chartURI !== props.chartURI);
                cpContext.userPrefs.fav_charts = arr
            })
            .catch( error => console.log("Could not get data " + error.message));      
        })
 // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[props.chartURI,setFav,cpContext.fav_charts]);

    
    


    return(
        <Navbar.Group align={Alignment.LEFT}> 
              <Popover content={
                    <Menu>
                        <MenuItem icon="document" text="PDF" onClick={() => download("pdf")}/>
                        <MenuItem icon="manually-entered-data" text="CSV" onClick={() => download("csv")}/>
                        <MenuItem icon="media" text="PNG" onClick={() => download("png")}/>
                    </Menu>
                } position={Position.BOTTOM}>
                 <Button className={Classes.MINIMAL} icon="cloud-download" rightIcon="caret-down"/>    
              </Popover>
              <NavbarDivider></NavbarDivider> 
              <NavbarHeading><div classsName="title">{props.chartName}</div></NavbarHeading>  
              <NavbarDivider></NavbarDivider> 
              <NavbarHeading>{button}</NavbarHeading> 

        </Navbar.Group>
    )


}
export default ChartHeader;