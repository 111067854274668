import React,{useContext, useEffect, useState} from 'react';
import {Card, Elevation, Icon, Button} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useHistory } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MsalContext } from "@azure/msal-react";
import {getToken,getTokens} from '../../auth/TokenManager'
import {tokenRequest} from '../../auth/msal/config'
import {getAPI} from '../../constants/ConfigManager'
import {CockpitContext} from '../../common/context/Context'
import {setDashboardForUser} from '../../common/userprefs/PrefsManager'

function FavouriteCard(props){

   
    const isAuthenticated = useIsAuthenticated()
    const authContext = useContext(MsalContext);
    const {userPrefs, setDashBoard} = useContext(CockpitContext);
    const {accounts} = useMsal();

    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const imgEl = React.useRef(null);



    const unPin = () => {
        setDashBoard({})
        setTheButton(pinButton)
        writeDashBoard(accounts[0].id,accounts[0].username,{})
    }

    const pin = () => {
        var dash = {
            chartName: props.chartName,
            chartURI: props.chartURI,
            chartID: props.chartID,
            workBookID: props.workBookID,
            workBookURI: props.workBookURI
        }
        setDashBoard(dash)
        setTheButton(unPinButton)
        writeDashBoard(accounts[0].id,accounts[0].username,dash)
    }


    const writeDashBoard = (accountid, tableauid, dashboard) =>{

        getTokens(authContext).then( (tokens) => {      
            setDashboardForUser(tokens[0].accessToken, tokens[1].accessToken, accountid, tableauid, dashboard)
            .catch( error => console.log("Could not set user dashboard" + error.message));  
        })  

    }



    const unPinButton = <Button onClick={unPin} title="Unpin from dashboard" icon={IconNames.BOOKMARK} minimal={true} small={true}></Button>
    const pinButton = <Button onClick={pin} title="Pin to dashboard" icon={IconNames.ADD_TO_ARTIFACT} minimal={true} small={true}></Button>

    useEffect(  () => {
        var dash = userPrefs.dashboard
        if(dash){
            var dashURI = dash.chartURI
            if(dashURI===props.chartURI){
                setTheButton(unPinButton)
            } else{
                setTheButton(pinButton)
            }
        } 
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userPrefs.dashboard,props.chartURI])

    const [theButton, setTheButton] = useState(pinButton)
   
   
    useEffect( () => {
        if(isAuthenticated){
        getToken(authContext,tokenRequest.scopes).then( result => {
            fetch(getAPI() + "/favourites/images/" + props.chartID,{
                method: 'GET',
                headers: {
                'Authorization': 'Bearer ' + result.accessToken
                }
            })
            .then(response => response.blob())
            .then(blob => {       
                const url = URL.createObjectURL(blob)
                imgEl.current.onload = () => {
                  URL.revokeObjectURL(url)
                }
                imgEl.current.src = url
                setLoaded(true)
            })
            .catch( error => console.log("Could not get data " + error.message));  
        })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[isAuthenticated])
    


    const openLink = () => {
        history.replace("/" + props.workBookURI + "/sheets/" + props.chartURI)
    }
  

   
    

    return(
            <div className="dashboard-column">
            <Card className="favourite-card" interactive={true} onClick={openLink} elevation={Elevation.FOUR}>
                 <Icon style={!loaded ? { display: 'inline-block' } : { display: 'none' }} className="cockpit-heading" icon={IconNames.CHART} iconSize={60}/>
                 <img className="favourite-card-image" alt="" ref={imgEl} style={loaded ? {} : { display: 'none' }}></img>
                
            </Card>
            <div className="favourite-title"><div>{theButton}</div>{props.chartName}</div>    
            
            </div>
       



    )


}

export default FavouriteCard
