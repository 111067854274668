import React, {useState} from 'react';
import './App.css';
import Cockpit from './cockpit/Cockpit';


import {config} from './auth/msal/config'
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
//import {TableauProvider} from './tableau/Auth/TableauAuth'
import mixpanel from 'mixpanel-browser';
import {MixpanelContext} from './mixpanel/withMixPanel'

//var pca = new PublicClientApplication(config)




function App() {


  const [pca] = useState(new PublicClientApplication(config))

  const productionHost = 'app.thecockpit.com'; 
  const devToken = 'cb96a3553fcb804881adf201f433d693'; 
  const prodToken = 'de6eca24860e12e67d102928189b00df';

  if(window.location.hostname.toLowerCase().search(productionHost) < 0) { 
    mixpanel.init(devToken);
  } else { 
    mixpanel.init(prodToken);
  } 

 

  return (
    <MsalProvider instance={pca}>
         <MixpanelContext.Provider value={mixpanel}>
            <Cockpit/>
         </MixpanelContext.Provider>
    </MsalProvider>
       
  
  );
}



export default App;
