import React from 'react';

import MenuItem from './MenuItem'
import MenuHeader from './MenuHeader'
import * as ROUTES from '../constants/routes';



const AdminMenu = (props) => {
 

  return (
    <div>
        <MenuHeader headingText="Admin" headingIcon="series-configuration"/>
        <MenuItem linkLocation={ROUTES.ADMIN + ROUTES.ADMIN_USERS} linkText="Users" / >
        <MenuItem linkLocation={ROUTES.ADMIN + ROUTES.ADMIN_ORGS} linkText="Organisations" / >
       
    </div>  
  );
}

export default AdminMenu;