import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import './MenuItem.css';




class MenuItem extends Component{



    render() {
        
        const {linkLocation, linkIcon, linkText } = this.props;
        return (
        <div className="menuitem">
            <Link className="navList__item-link" to={linkLocation}><Icon className="navList__item-link-icon" icon={linkIcon} /><span className="navList__item-link-text">{linkText}</span></Link>
        </div>
        
        );
    }

}

export default MenuItem;