

export const getAPI = () => {
    const productionHost = 'app.thecockpit.com'
    const uat = 'mango-desert-0d4454a03'

    //var api = "https://cruxy-cockpit-api-dev.azurewebsites.net/api"
    var api = "https://api-thecockpit-develop.azurewebsites.net/api"
    
    const loc = window.location.hostname.toLowerCase()
    if(loc.search(productionHost) !== -1 || loc.search(uat) !== -1) { 
      api= "https://api-thecockpit.azurewebsites.net/api"
    }
    
    return  api
}

