import {getAPI} from '../../constants/ConfigManager'


export const validateFavourites = (token, fav_charts, workBookData) => {
    // go through each fav and check it's in the workbooks list...
    // if it is also check the name is still valid
    // if not delete from favourites.
    var deletes = []
    if(fav_charts){
        fav_charts.forEach( (fav, index) => {
            var isValid = false
            workBookData.forEach(book => {  
                var view = book.views.find( aview => aview.viewUrlName === fav.chartURI)
                if(view){
                    // it's a match
                    // check the name and workbook
                    const favWB = fav.workBookURI
                    const favName = fav.chartName
                    if(favWB===book.$.contentUrl) isValid = true
                    if(favName!==view.name){
                    fav.chartName = view.name
                    updateFavourite(token,fav)
                    }
                } 
            })  

            if(!isValid){
                // need to remove this from the charts - mark if for deletion
            deletes.push(index)
            }
        })
    }
  
    // do the removes
    while(deletes.length) {
        const index = deletes.pop()
        // remove it from charts in app
        const deletedFavs = fav_charts.splice(index, 1);
        // update hte userPrefs via api - no need to action the promise....
        deleteFavourite(token,deletedFavs[0])
    }
  
}

export const deleteFavourite = (token, fav) => {
    return fetch(getAPI() + '/favourites/'+fav.chartURI,{
        method: 'DELETE',
        headers: {
        'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json())
    
}


export const addFavourite = (token, workBookURI, workBookID, chartURI,chartName,chartID) => {

    const thefav = 
    {
        "workBookURI": workBookURI,
        "workBookID": workBookID,
        "chartID": chartID,
        "chartURI": chartURI,
        "chartName": chartName
    }
    const fav = {
    "fav": thefav
    }
    return fetch(getAPI() + '/favourites',{
        method: 'POST',
        headers: {
        'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(fav)
        })
        .then(response =>  response.json())
        // needs to return the fav...... not the JSON response
}

const updateFavourite = (token, fav) => {
    const favs = {
        "fav": fav
    }
    return fetch(getAPI() + '/favourites',{
        method: 'PUT',
        headers: {
        'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(favs)
    })
    .then(response => response.json())
    
}