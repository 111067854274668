import classNames from "classnames"
import React, {useEffect, useState,useContext} from "react";
import { MsalContext } from "@azure/msal-react";
import {ControlGroup, InputGroup, HTMLSelect, Label, H5,Overlay,Classes,Button,Intent} from "@blueprintjs/core";
import {getTokens} from '../auth/TokenManager'
import {getAPI} from '../constants/ConfigManager'
import {getUserPrefsForAdmin, setDashboardForUser} from '../common/userprefs/PrefsManager'
import { Column, Table, Cell, ColumnHeaderCell} from "@blueprintjs/table";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/table/lib/css/table.css";

export default function AdminOrgs() {

    const defaultUser = {displayName: "",id: ""}
    const authContext = useContext(MsalContext)


    const [users, setUsers] = useState([])
    const [user, setUser] = useState(defaultUser)
    const [tabID, setTabID] = useState("")

    const [isOpen, setIsOpen] = useState(false)
   
    const [userCharts, setUserCharts] = useState([])
    const [chartMap, setChartMap] = useState()
    const [dashBoardOptions, setDashBoardOptions] = useState([""])
    const [dashBoardOptionsFiltered, setDashBoardOptionsFiltered] = useState([""])
    const [chartFilter, setChartFilterValue] = useState()
    const [dashBoard,setDashBoard] = useState()


    // user has been set - get account and chart infoooo
    useEffect( () => {

        if(user && user.id!==""){
          // Got a proper user... We have an issue with graph not returning the accountName but an id and mangled UPN so try to get username
          // from the user preft
          console.log("Getting charts for user")
            getTokens(authContext).then( (tokens) => {      
                



              getUserPrefsForAdmin(tokens[0].accessToken,tokens[1].accessToken,user.id).then( userprefs => {
                var username
                if(!userprefs || !userprefs.userid){
                  // no prefs so need to guess at user id
                  var upn = user.userPrincipalName
                  var flatid = upn.substring(0,upn.lastIndexOf("#EXT#"));
                  var index = flatid.lastIndexOf("_")
                  var guessID = flatid.substr(0, index) + "@" + flatid.substr(index + 1);
                  username = guessID
                  
                } else{
                  username = userprefs.userid
                }
                setTabID(username)
                getChartsForUser(username,tokens[0].accessToken,tokens[1].accessToken)
              })
                
            }) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[user])


    useEffect( () => {
      // userCharts updated so setup the drop-down list
      if(userCharts){

        let cmap = new Map()

        var def = {label: "", value: ""}
        var opts = [def]
        userCharts.forEach( wb => {
          var views = wb.views
          const workBookURI = wb.$.contentUrl
          const workBookID = wb.$.id
          views.forEach( view => {
            
            var viewData = {
              "chartID": view.id,
              "chartURI": view.viewUrlName,
              "chartName": view.name,
              "workBookID": workBookID,
              "workBookURI": workBookURI
            }
            cmap.set(view.id, viewData)
            opts.push({value: view.id,label: view.name})
          })
        })
         setChartMap(cmap)
         setDashBoardOptions(opts)  
         setDashBoardOptionsFiltered(opts)    
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[userCharts])

    // getUsers
    useEffect( () => {   
        if(users.length===0){
            getTokens(authContext).then( (tokens) => {      
                fetch(getAPI() + '/users',{
                    method: 'GET',
                    headers: {
                    'Authorization': 'Bearer ' + tokens[0].accessToken,
                    'access-token': tokens[1].accessToken
                    }
                })
                .then(response => response.json())
                .then(data => {  
                        setUsers(data)
                    })
                .catch( error => console.log("Could not get org data " + error.message)); 
             
          
            })     
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[users.length])

    // Set the dashBoard chart options for the dropdown
    useEffect( () => {
      if(chartFilter && chartFilter!==""){   
          var filterOptions = dashBoardOptions.filter(options => {
              return options.toLowerCase().includes(chartFilter.toLowerCase())
          })
          setDashBoardOptionsFiltered(filterOptions)
      }
    },[chartFilter,dashBoardOptions])



    const getChartsForUser = ( (username, cockpitToken, graphToken) => {
      fetch(getAPI() + '/users/'+ username + '/charts',{
        method: 'GET',
        headers: {
        'Authorization': 'Bearer ' + cockpitToken,
        'access-token': graphToken
        }
      })
      .then(response => response.json())
      .then(data => setUserCharts(data))
    })



    // set the chart list filter
    const handleFilterChange = ( (event) => {
      setChartFilterValue(event.target.value)
    })

    const handleTableauIDChange = ( (event) => {
      setTabID(event.target.value)
    })
    
    const userOverlay = ((i) => {
       setUser(users[i])
       setDashBoard("")
       setIsOpen(true)
    })

    const closeOverlay = ((i) => {
        setIsOpen(false)
        setDashBoard("")
     })

     // user has selected a chart in drop down
     const setDashBoardValue = ((event) => {
      setDashBoard(event.target.value)
    })


    const refreshCharts = ( (event) => {
      getTokens(authContext).then( (tokens) => {      
        getChartsForUser(tabID,tokens[0].accessToken,tokens[1].accessToken)
      })
    })
    
     const saveUser = ((i) => {
      setIsOpen(false)
      var viewData  = chartMap.get(dashBoard)
      if(!viewData) viewData = {}
      // set the dashboard object in user prefs
      getTokens(authContext).then( (tokens) => {      
      
        setDashboardForUser(tokens[0].accessToken, tokens[1].accessToken, user.id, tabID, viewData)
        .then(data => {  
                console.log("Set user dashboard ")
            })
        .catch( error => console.log("Could not set user dashboard" + error.message)); 
     
  
    })    



      })

    const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "user-overlay"
    );

    const refreshButton = <Button icon="refresh" text="Refresh Charts" small={true} minimal={true} onClick={refreshCharts} />

    return(
        <div className="admin-users">
           <H5>User Management - Select User</H5>
          
           <Table numRows={users.length}>
           <Column
                    columnHeaderCellRenderer={() => (
                      <ColumnHeaderCell name="User" />
                    )}
                    cellRenderer={i =>
                      users[i] ? (
                        <Cell interactive={true}>
                        <div className="adminlink" onClick={() => userOverlay(i)}>
                         {users[i].displayName}
                        </div>
                        </Cell>
                      ) : (
                        <Cell />
                      )
                    }
            />
             <Column
                    columnHeaderCellRenderer={() => (
                      <ColumnHeaderCell name="Given Name" />
                    )}
                    cellRenderer={i =>
                      users[i] ? (
                        <Cell>
                         {users[i].givenName}
                        </Cell>
                      ) : (
                        <Cell />
                      )
                    }
            />
             <Column
                    columnHeaderCellRenderer={() => (
                      <ColumnHeaderCell name="Surname" />
                    )}
                    cellRenderer={i =>
                      users[i] ? (
                        <Cell>
                         {users[i].surname}
                        </Cell>
                      ) : (
                        <Cell />
                      )
                    }
            />
            <Column
                    columnHeaderCellRenderer={() => (
                      <ColumnHeaderCell name="UPN"/>
                    )}
                    cellRenderer={i =>
                      users[i] ? (
                        <Cell>
                         {users[i].userPrincipalName}
                        </Cell>
                      ) : (
                        <Cell />
                      )
                    }
            />
            </Table>

            <Overlay  isOpen={isOpen} usePortal={true} className={Classes.OVERLAY_SCROLL_CONTAINER} >
                <div className={classes}>
                        
                        <div className="user-overlay-group">
                        <Label htmlFor="input-b">{tabID} - {user.displayName}</Label>
                        </div>
                        <div className="user-overlay-group">
                            <ControlGroup className="user-overlay-group" fill={true} vertical={false}>
                                <Label>Tableau ID: </Label>
                                <InputGroup  asyncControl="true" value={tabID} onChange={handleTableauIDChange} rightElement={refreshButton}/>
                            </ControlGroup> 
                            <ControlGroup fill={true} vertical={true}>
                                <div className="user-overlay-group">
                                  <Label>Dashboard: </Label>
                                  <InputGroup placeholder="Filter available charts..." asyncControl="true" value={chartFilter} onChange={handleFilterChange}/>
                                </div>
                                <div>
                                  <Label>Available Charts:</Label>
                                  <HTMLSelect options={dashBoardOptionsFiltered} onChange={setDashBoardValue}/>
                                  </div>
                            </ControlGroup> 
                        </div>
                        <div className="user-overlay-group">
                          <div className="user-overlay-row">
                            <div className="user-overlay-row-item">
                            <Button intent={Intent.PRIMARY} onClick={saveUser}>
                                    Update
                            </Button>
                            </div>
                            <div className="user-overlay-row-item">
                            <Button intent={Intent.SUCCESS} onClick={closeOverlay}>
                                    Close
                            </Button>
                            </div>
                          </div>
                        </div>
                </div>

            </Overlay>       

        </div>
    )


}


