
import {getAPI} from '../../constants/ConfigManager'

export const getUserPrefsForAdmin = (cockpittoken, graphToken, target_user_id) => {
   
    return fetch(getAPI() + '/users/'+ target_user_id + '/userprefs',{
        method: 'GET',
        headers: {
        'Authorization': 'Bearer ' + cockpittoken,
        'access-token': graphToken
        }
    })
    .then(response => response.json())
    
}


export const setDashboardForUser = (cockpitToken, graphToken, target_id, target_username, viewData) => {

    const dash = 
    {
        "dashboard": viewData,
        "account_id": target_id
    }

    return fetch(getAPI() + '/users/'+target_username + "/dashboard",{
        method: 'POST',
        headers: {
        'Authorization': 'Bearer ' + cockpitToken,
        'access-token': graphToken
        },
        body: JSON.stringify(dash)
        })
        .then(response =>  response.json())
}