import React, {useEffect,useContext, useState} from "react";
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';

import { useIsAuthenticated } from "@azure/msal-react";
import { MsalContext,useMsal } from "@azure/msal-react";
import { Icon, Alert} from "@blueprintjs/core";
import { useIdleTimer } from 'react-idle-timer'
import {MixpanelContext} from '../mixpanel/withMixPanel'

import * as ROUTES from '../constants/routes';
import cruxylogo from '../cruxy-white.png';
import DiceMenu from '../menu/Menu'
import HeaderBar from '../header/HeaderBar'
import Admin from '../admin/Admin'
import ChartRoutes from '../tableau/ChartRoutes'
import Dashboard from '../dashboard/Dashboard';
import SignIn from '../auth/login/SignIn'
import {tokenRequest,graphRequest} from '../auth/msal/config'
import {getToken} from '../auth/TokenManager'
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import {getAPI} from '../constants/ConfigManager'
import {validateFavourites} from '../common/favourites/FavouriteManager'
import {CockpitContext} from '../common/context/Context'
import './Cockpit.css';


export default function Cockpit(props) {

    const isAuthenticated = useIsAuthenticated()
    const [isTimeout, setIsTimeout] = useState(false)
    const [showTimeoutAlert, setShowTimeoutAlert] = useState(false)
    const [isOpen, setIsOpen] = useState(true) 
    const [workBooks, setWorkBooks] = useState([])
    const [isUser, setIsUser] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [userPrefs, setUserPrefs] = useState()
    const [headerItem, setHeaderItem] = useState(<div></div>)

    const authContext = useContext(MsalContext);
    const { instance} = useMsal();


    const mixpanel = useContext(MixpanelContext);
   
    const logout = () => {
        mixpanel.track('Logout')
       
        instance.logout()
    };



    const handleOnIdle = event => {

        console.log("User is Idle out")
        if(isAuthenticated){
            
            if(isTimeout){
               logout()
            }else{
                setIsTimeout(true)
                setShowTimeoutAlert(true)
                timer.reset()
            }
        }
    }

    const resetIdle = () => {
        setIsTimeout(false)
        setShowTimeoutAlert(false)
    }

    // hour of timeout, 1 min once the alert is popped... 
    var timeout = 3600 * 1000
    if(isTimeout) timeout = 60 * 1000

    const timer = useIdleTimer({
        timeout: timeout,
        onIdle: handleOnIdle
    })



    useEffect( () => {
        if(isAuthenticated){
           
            getToken(authContext,graphRequest.scopes).then( result => {
                fetch('https://graph.microsoft.com/v1.0/me/memberOf',{
                    method: 'GET',
                    headers: {
                    'Authorization': 'Bearer ' + result.accessToken
                    }
                })
                .then(response => response.json())
                .then(data => {
                        var user = false;
                        var admin = false;
                        data.value.forEach(group => {
                            const name = group.displayName
                            if(name==='CockpitUser'){
                                user = true
                            }
                            if(name==='CockpitAdmin'){
                                admin = true
                            }
                            
                        })
                        setIsAdmin(admin)
                        setIsUser(user)
                    })
                     .catch( error => console.log("Could not get data " + error.message));  
            }) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isAuthenticated])



    useEffect( () => {
        if(isAuthenticated){

            getToken(authContext,tokenRequest.scopes).then( result => {

                fetch(getAPI() + '/GetCharts',{
                    method: 'GET',
                    headers: {
                    'Authorization': 'Bearer ' + result.accessToken
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if(!checkIntro){
                        introJs().start()
                        document.cookie="Intro=true"
                    }   
                    setWorkBooks(data)
                    
                })
                .catch( error => console.log("Could not get data " + error.message)); 
            })

        } 
     // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[isAuthenticated])

    useEffect( () => {
        if(isAuthenticated){
        getToken(authContext,tokenRequest.scopes).then( result => {
            fetch(getAPI() + "/user-prefs",{
                method: 'GET',
                headers: {
                'Authorization': 'Bearer ' + result.accessToken
                }
            })
            .then(response => response.json())
            .then(data => {  
                if(!data.fav_charts) data.fav_charts = [] 
                setUserPrefs(data)
            })
            .catch( error => console.log("Could not get data " + error.message));  
        })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[isAuthenticated])

    
    useEffect( () => {
        if(workBooks.length!==0 && userPrefs){
            getToken(authContext,tokenRequest.scopes).then( result => {
                validateFavourites(result.accessToken,userPrefs.fav_charts,workBooks)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[workBooks,userPrefs])


    const checkIntro = () =>{
        var intro =  document.cookie.split(';').find(val => val.trim().startsWith('Intro'))
        return intro;
     }


   

   

    const toggleOpenClose = () => {
        const currentState = isOpen
        setIsOpen(!currentState)
    }

   
    const setHeader = (item) =>{
       setHeaderItem(item)
    }
    const clearHeader = (item) =>{
       setHeaderItem(<div></div>)
    }


    const setDashBoard = (adashboard) =>{
         var prefs = {
            "accountid": userPrefs.accountid,
            "userid" : userPrefs.userid,
            "fav_charts": userPrefs.fav_charts,
            "dashboard": adashboard
        }
        setUserPrefs(prefs)
    }

  
    return(

        <div className="cockpit">
        <Router>
        <CockpitContext.Provider value={{workBooks, userPrefs, setDashBoard}}>
        <HeaderBar isOpen={isOpen} toggleOpenClose={toggleOpenClose}>{headerItem}</HeaderBar>
        <div className="app">

            <div className={(isOpen ? "sideBar full bp3-dark" : "sideBar minimised bp3-dark")}>
                <div className="sideBarHeader">
                    <div className="headingItem">
                        <Link className="header-link" to={ROUTES.DASHBOARD}><img src={cruxylogo} className="logo" alt="Cruxy" /></Link>
                    </div>
                    <div className="headingItem">
                        <Icon icon="one-column" iconSize={20} className="switch" onClick={(event) => toggleOpenClose()} />
                    </div>
                </div>
                <div className="menu"> 
                <DiceMenu isUser={isUser} isAdmin={isAdmin} isOpen={isOpen} />
                </div>
            </div> 

        <main className="cockpit-content">
            <div className="appBarSpacer"></div>
            <div className="content">
                    <Route path={ROUTES.LOGIN}>
                       <SignIn></SignIn>
                   </Route>
                   <ChartRoutes removeHeaderItem={clearHeader} setHeaderItem={setHeader}></ChartRoutes>
                   <Route path={ROUTES.ADMIN}>
                       <Admin isAdmin={isAdmin}></Admin>
                   </Route> 
                   <Route exact default path={ROUTES.DASHBOARD}> 
                       <Dashboard></Dashboard>
                   </Route> 
            </div> 
            <Alert
                    confirmButtonText="Logout"
                    cancelButtonText="Stay"
                    isOpen={showTimeoutAlert}
                    onConfirm={logout}
                    onCancel={resetIdle}
                >
                    <p>
                       You've been inactive for sometime. You'll be automatically signed-out shortly.
                    </p>
            </Alert>   
        </main>
        </div>
        </CockpitContext.Provider>
       </Router>
</div>
          

    )

}


