import React, { useEffect, useContext,useState} from 'react';
import './Dashboard.css'
import MSALLogin  from '../auth/login/MSALLogin'
import {withMixpanel} from '../mixpanel/withMixPanel'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { MsalContext } from "@azure/msal-react";
import {  Card, Elevation} from "@blueprintjs/core";

import FavouritePanel from './Favourites/FavouritePanel'
import {CockpitContext} from '../common/context/Context'
import TableauReportDice from '../tableau/Embed/TableauReportDice'
import {TABLEAU_BASE_URL} from '../constants/routes'


function Dashboard(props){

    const authContext = useContext(MsalContext);
    const {userPrefs} = useContext(CockpitContext);
    
    const isAuthenticated = useIsAuthenticated()
    const [isSafari,setIsSafari] = useState(false)
    const [dashBoardItem, setDashBoardItem] = useState()
    
    useEffect( () => {
        if(isAuthenticated){
            props.mixpanel.identify(authContext.accounts[0].username)
            props.mixpanel.track('Login')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isAuthenticated])

    useEffect( () => {
        let agent = navigator.userAgent
        let safariAgent = agent.indexOf("Safari") > -1; 
        let chromeAgent = agent.indexOf("Chrome") > -1; 
        if ((chromeAgent) && (safariAgent)) safariAgent = false; 
        setIsSafari(safariAgent)
    },[])

    const parameters = {
        tabs: 'No',
        hideTabs: true,
        hideToolbar: true,
        showAppBanner: false,
        display_count: 'n'
    };


    useEffect( () => {
        if(userPrefs){
            var dash = userPrefs.dashboard
            if(dash && dash.chartName){
                const url = TABLEAU_BASE_URL + dash.workBookURI + "/" + dash.chartURI
                setDashBoardItem(
                    <div>
                    <TableauReportDice containerClass="dashboard-chart" parameters={parameters} url={url} />
                    </div>
                )
            }else{
                setDashBoardItem(
                    <div className="dashboard-row dashboard-centre"> 
                        <Card className="cockpit-card-row" interactive={false} elevation={Elevation.ONE}>
                            Cockpit Dashboard
                        </Card>
                    </div>
                   
                )
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userPrefs])



    var login = <MSALLogin></MSALLogin>
    if(isSafari){
        login = <div>Cockpit is not currently supported on Safari. Please use Chrome or Firefox</div>
    }
    
  

        return (
            <div className="dashboard" >

                <UnauthenticatedTemplate>
                    <div className="dashboard-centre">
                        <div className="cruxy-dark">Welcome to The Cockpit</div>
                        <div className="login-area">
                        {login}
                        </div>
                    </div>
                    
                </UnauthenticatedTemplate>

                <AuthenticatedTemplate>
                     
                       
                    {dashBoardItem}
                       
                     <FavouritePanel></FavouritePanel>
                    <div data-position='top' data-step="2" data-intro="Your data will be made available for analysis and drill down here. " data-title="Dashboard"></div>
                </AuthenticatedTemplate>
                
               
            
            </div>

            
        )

   

}


export default withMixpanel(Dashboard)