import * as React from "react";
import { useState } from "react";



export const TableauContext = React.createContext();


export function TableauProvider({children}){
   
    const [authenticated, updateAuthenticated] = useState(false)

    if(!authenticated && document.cookie === "TabAuthed"){
        updateAuthenticated(true)
    }
    
    const contextValue = {
        authenticated: authenticated,
        logIn: loggedIn
    }
    return (
        <TableauContext.Provider value={contextValue}>
            {children}
        </TableauContext.Provider>
    );

    function loggedIn(){
       if(!authenticated){
        loginTableuUser();
        document.cookie = "TabAuthed"
        updateAuthenticated(true)
       }
    }

}


export function loginTableuUser(){
        var uri = 'https://dub01.online.tableau.com/#/signin?closePopupWhenDone=true&site=cruxy&authSetting=SAML&siteLuid=592e4e19-36f5-4513-97ea-ed28deb75c47&embedded=true'
        var width=400
        var height=600
        var screenLeft = window.screenLeft || window.screenX;
        var outerWidth = window.outerWidth || window.innerWidth;
        var screenTop = window.screenTop || window.screenY;
        var outerHeight = window.outerHeight || window.innerHeight;
        var left = screenLeft + (outerWidth - width) / 2;
        var top = screenTop + (outerHeight - height) / 8;
        var popupOptionsString = "width=" + width + ",height="+height+",scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,left=" +left + ",top=" + top
        window.open(uri, 'TableauAuth', popupOptionsString);
}