import React, {useContext} from 'react'

import { Popover, Icon, NavbarDivider,Navbar, Alignment, Button, Menu, MenuItem, MenuDivider, Position} from "@blueprintjs/core"


import { useIsAuthenticated,useMsal } from "@azure/msal-react"
import { useHistory } from "react-router-dom";
import {MixpanelContext} from '../mixpanel/withMixPanel'
import './HeaderBar.css'


const HeaderBar = (props) => {
 
    const isAuthenticated = useIsAuthenticated();
    let history = useHistory();
    const { instance, accounts} = useMsal();
    const mixpanel = useContext(MixpanelContext);


    const logout = () => {
        mixpanel.track('Logout')
        history.push("/")
        instance.logout()
    };

    var user = <div></div>
    var menuItem = <div></div>
    if(isAuthenticated){
       user = 
       <Navbar.Group align={Alignment.LEFT}> 
                <Button  icon="home" minimal="true" onClick={() => history.push("/")}></Button>
                <NavbarDivider></NavbarDivider> 
              <Popover content={
                    <Menu>
                        <MenuDivider title={accounts[0].name} />
                        <MenuItem icon="log-out" onClick={logout} text="Sign Out"/>
                    </Menu>
                } position={Position.BOTTOM}>
                <Button icon="user" rightIcon="caret-down" minimal></Button>
              </Popover>
        </Navbar.Group> 

        menuItem = props.children
    }


    return (

        <header className={(props.isOpen ? "appBar appBarShifted bp3-dark" : "appBar appBarFull bp3-dark")}>
            <div className="opener headingItem">
            <Icon icon="two-columns" iconSize={20} onClick={(event) => props.toggleOpenClose()} />
            </div>
            <div>{menuItem}</div>
            <div className="headerRightGroup">
            {user}
            </div>
        </header>
        )
    



}

export default HeaderBar;