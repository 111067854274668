import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


export const withAuthorisation = (Component) =>{  
   
  class WithAuthorisation extends React.Component{
      
      render(){
        return(      
             <React.Fragment>
             <AuthenticatedTemplate>
             <Component {...this.props}/>
               </AuthenticatedTemplate>
               <UnauthenticatedTemplate>
                   <div/>
               </UnauthenticatedTemplate>
             </React.Fragment>          
       );
      }

  }


  return WithAuthorisation          
    
};

