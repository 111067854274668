import React,{useEffect,useState} from 'react';
import { Position, Tooltip, Tree } from "@blueprintjs/core";

import { useHistory } from "react-router-dom";



const WorkBookMenuItem = (props) => { 

  const [tree,setTree] = useState([])
  const [redraw,setRedraw] = useState(false)
  const workBooks = props.workBooks
  const history = useHistory();
  const showItems = props.showItems


  

    useEffect( () => {
      
      if(workBooks && workBooks.length!==0 && showItems){
          setTree(buildTree(workBooks,props.isOpen))
      }else{
          setTree([])
      }
    },[workBooks,props.isOpen,showItems])


   const buildTree = (workBooks,isOpen) => {
    
    const initial_tree = []
    
    workBooks.forEach( (book,index) => {
      // create the parent for the workbook
      var label = "..."
      if(isOpen) label = book.$.name
      var tooltip = book.$.name
      if(typeof book.$.description!=='undefined' && book.$.description!=="") tooltip = tooltip + " : " + book.$.description
      var book_entry = {
        id: index,
        hasCaret: true,
        icon: "folder-close",
        label: (
            <Tooltip modifiers={{
              preventOverflow: { enabled: false },
              hide: {
                enabled: false
              },
              flip: { enabled: false }
            }} content={tooltip} position={Position.RIGHT}>
            {label}
            </Tooltip>
        )
      }
      // add the views/reports as children
      var childNodes = []
      book.views.forEach( (view,aindex) => {
        var tooltip = view.name
        var label = "..."
        if(isOpen) label = view.name
        return childNodes.push({
            id: aindex,
            icon: "chart",      
            label:(
              <Tooltip modifiers={{
                preventOverflow: { enabled: false },
                hide: {
                  enabled: false
                },
                flip: { enabled: false }
              }} content={tooltip} position={Position.RIGHT}>
              {label}
              </Tooltip>
          ),
            contentURL: view.contentUrl
          })
        })
      book_entry.childNodes = childNodes
      initial_tree.push(book_entry)
    })
    
    return initial_tree
   }
 
  
  
  const handleNodeClick = (nodeData, _nodePath, e) => {
     
      const originallySelected = nodeData.isSelected;
      if (!e.shiftKey) {
          forEachNode(tree, n => (n.isSelected = false));
      }
      nodeData.isSelected = originallySelected == null ? true : !originallySelected;
      const children = nodeData.childNodes
      if(typeof children==='undefined' || children.length===0){
          history.replace("/" + nodeData.contentURL)
      }
  }
  
  const handleNodeCollapse = (nodeData) => {
    nodeData.isExpanded = false;
   // this.setState(this.state);
   setRedraw(!redraw)
  };
  
  const handleNodeExpand = (nodeData) => { 
    nodeData.isExpanded = true;
    // this.setState(this.state);
    setRedraw(!redraw)
  };

  
  const forEachNode = (nodes, callback) => {
    if (nodes == null) {
        return;
    }

    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes, callback);
    }
  }

  

return (
          <div>
           <Tree  onNodeClick={handleNodeClick} onNodeCollapse={handleNodeCollapse}
                     onNodeExpand={handleNodeExpand}
                     className="chartmenu"
                      contents={tree}/> 
          </div>
) 
      

}


export default WorkBookMenuItem;