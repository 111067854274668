import React, { Component } from 'react';
import { Icon } from "@blueprintjs/core";
import './MenuItem.css';




class MenuHeader extends Component{



    render() {
        
        const {headingIcon, headingText } = this.props;
        return (
        <div className="menuheading">
            <div className="icon">
            <Icon icon={headingIcon} iconSize={Icon.SIZE_LARGE}  />
            </div>
            <div className="title">
                {headingText}
            </div>
        </div>
        );
    }

}

export default MenuHeader;