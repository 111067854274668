import React from "react";
import AdminOrgs from './AdminOrgs'
import AdminUsers from './AdminUsers'
import * as ROUTES from '../constants/routes';
import './Admin.css'
import {Route,useRouteMatch} from "react-router-dom"
import { useIsAuthenticated } from "@azure/msal-react";


export default function Admin(props) {
    let match = useRouteMatch();

    const isAuthenticated = useIsAuthenticated();

    var cont = <div></div>
    if(isAuthenticated && props.isAdmin){
        cont =  <div>
                <Route path={`${match.path + ROUTES.ADMIN_ORGS}`}>
                  <AdminOrgs/>
                </Route>
                <Route path={`${match.path + ROUTES.ADMIN_USERS}`}>
                 <AdminUsers/>
                </Route>
                </div>

    }

    return(

        <div className="admin">
        {cont}
        </div>
    )


}