import React from 'react';
import { FormGroup, InputGroup, Button, Tooltip, Intent, Alert } from '@blueprintjs/core';
import * as ROUTES from '../../constants/routes';

import './SignIn.css'

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    showPassword: false
  };

class SignIn extends React.Component {
      
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        
      }

    render(){
       
        const { showPassword, email, password, error } = this.state;
       
        const lockButton = (
            <Tooltip content={`${showPassword ? "Hide" : "Show"} Password`}>
              <Button
                icon={showPassword ? "unlock" : "lock"}
                intent={Intent.WARNING}
                minimal={true}
                onClick={this.handleLockClick}
              />
            </Tooltip>
          );
       
        return(

        <div className="login">
        <form onSubmit={this.onSubmit}>
          <FormGroup
            label="Email"
            intent="primary"
            labelFor="text-input"
            labelInfo="(required)"
            contentClassName="loginlabel"
          >
            <InputGroup id="email" name="email" onChange={this.onChange} defaultValue={email} placeholder="email address" type="text" />
          </FormGroup>
          <FormGroup
            label="Password"
            intent="primary"
            labelFor="text-input"
            labelInfo="(required)"
          >
            <InputGroup id="password" name="password" onChange={this.onChange} defaultValue={password} placeholder="password" type={showPassword ? "test" : "password"} rightElement={lockButton} />
          </FormGroup>

          <Button rightIcon="arrow-right" intent="success" text="Sign In" type="submit" />
          <Alert confirmButtonText="OK" isOpen={error} onClose={this.handleErrorClose}>
            {error && <p>{error.message}</p>}
          </Alert>
        </form>
      </div>
        );
    }

    handleLockClick = () => this.setState({ showPassword: !this.state.showPassword });
    handleErrorClose = () => this.setState({ error: false });

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    onSubmit= (event) => {
        event.preventDefault();
        const { email, password } = this.state;
        try{
            this.props.authenticator.signInWithPassword(email, password);
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.DASHBOARD);
        } catch(error){
            this.setState({error});
        };
      

    };

   


}

export default SignIn