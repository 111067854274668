import React,{useContext,useCallback, useEffect,useState} from 'react';
import {  Spinner } from "@blueprintjs/core";
import MenuHeader from './MenuHeader'
import {CockpitContext} from '../common/context/Context'
import WorkBookMenuItem from './WorkBookMenuItem';



const WorkBookMenu = (props) => { 

  const cpContext = useContext(CockpitContext);
  const [isLoading,setIsLoading] = useState(true)
  const [workBookMap,setWorkBookMap] = useState()
  const [menuItems,setMenuItems] = useState([])
  const [openProject, setOpenProject] = useState([])
  const isOpen = props.isOpen

    useEffect( () => {
      const workbooks = cpContext.workBooks
      if(workbooks && workbooks.length!==0){
          var map = new Map()
          workbooks.forEach(wb => {
             const projectName = wb.project[0].$.name
             if(map.has(projectName)){
               var books = map.get(projectName)
               books.push(wb)
             }else{
               map.set(projectName,[wb])
             }
          })
          setWorkBookMap(map)
          setIsLoading(false)
      }
    },[cpContext.workBooks,props.isOpen])


    

    const selectOpenProject = useCallback( (e, name) => {
      e.preventDefault()
      if(openProject===name) name = "" 
      setOpenProject(name)
     },[openProject])

    useEffect( () => {
      var items = []
      if(workBookMap){
        
        workBookMap.forEach( (wb,wbname) => {
          var showItems = false;
          if(wbname===openProject) {
            showItems = true
          }
          var label = wbname
          if(!isOpen) label = wbname.slice(0,3) + "..."
          items.push(
            <div key={wbname}>
              <a className="projectLink" href="/" onClick={ (event) => selectOpenProject(event,wbname)}>
              <MenuHeader headingText={label} headingIcon="grouped-bar-chart"/>
              </a>
              <WorkBookMenuItem  showItems={showItems} workBooks={wb} isOpen={isOpen}></WorkBookMenuItem>
            </div>

          )
        })
        setMenuItems(items)
      }

    },[workBookMap,isOpen,openProject,selectOpenProject])



return (
          <div>
            {
              isLoading ? <Spinner className="chartmenu" size={25}/>  : menuItems
            }         
          </div>
) 
      

}


export default WorkBookMenu;