import React from 'react';


export const MixpanelContext = React.createContext()

export const withMixpanel = (Component) =>{  
   
  class WithMixpanel extends React.Component{
      
      render(){
        return(        
            <MixpanelContext.Consumer>
                {value => <Component mixpanel={value} {...this.props}/>}
            </MixpanelContext.Consumer>     
       );
      }

  }


  return WithMixpanel          
    
};

