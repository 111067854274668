import React from 'react';
import {Button } from '@blueprintjs/core';
import { useMsal } from "@azure/msal-react";
import {withMixpanel} from '../../mixpanel/withMixPanel'

const MSALLogin = (props) => {
 
    const { instance, accounts, inProgress } = useMsal();


    const login= () => {
      //  props.mixpanel.track('Login');
     //   console.log("LOGIN")
      instance.loginPopup();
    }

    if (accounts.length > 0) {
        return <span>There are currently {accounts.length} users signed in!</span>
    } else if (inProgress === "login") {
        return <span>Login is currently in progress!</span>
    } else {
        return (
            <>
                <Button intent="success" icon="user" color="primary" onClick={() => login()}>Login</Button>
            </>
        );
    }

    

}

export default withMixpanel(MSALLogin)

