import React,{useContext} from 'react';
import { Route} from 'react-router-dom';
import Chart from '../tableau/Chart'
import {CockpitContext} from '../common/context/Context'

const ChartRoutes = (props) => {
  
    
    const {workBooks} = useContext(CockpitContext);


    var charts = []
    
    workBooks.forEach(book => {    
      if(book.views){
        book.views.forEach((view,index) => {
            const path = "/" + view.contentUrl
            const name = view.name
            var chart = (
                <Route key={path} path={path}>
                    <Chart removeHeaderItem={props.removeHeaderItem} setHeaderItem={props.setHeaderItem} chartID={view.id} workBookID={book.$.id} chartName={name} workBookURI={book.$.contentUrl} chartURI={view.viewUrlName} ></Chart>
                </Route> 
            )
            charts.push(chart)
        })
      }
    })

  return (
    <div>
      {
       charts
      } 
    </div>
  );
}

export default ChartRoutes;