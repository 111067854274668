import React from 'react';
import './Menu.css'
import {withAuthorisation} from '../auth/withAuthorisation'
import WorkBookMenu from './WorkBookMenu'
import AdminMenu from './AdminMenu'
import {withTableau} from '../tableau/Auth/withTableau'


const DiceMenu = (props) => {


      
      const {isUser,isAdmin} = props
      let data = <div></div>
      let admin = <div></div>
      if(isUser){
           data = <WorkBookMenu isOpen={props.isOpen}/> 
      }
      if(isAdmin){
        admin =  <AdminMenu></AdminMenu>    
      }

     
        return(
            <div className="bp3-dark" data-step="1" data-intro="Your Cockpit is organised into folders with a topic, each chart is from your data menu here. " data-title="Data">     
                {admin} 
                {data}
            </div>
        );
       
    




}


export default withAuthorisation(withTableau(DiceMenu))