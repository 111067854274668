export const config = {
  auth:{
    clientId: '3360310b-cb20-4b1a-a588-7a2d700c5632',
    authority: 'https://login.microsoftonline.com/38c47d5a-6196-47a1-b545-44bfcfc9a6a5',
    scopes: [
      'user.read openid'
    ]
    }, cache: {
      cacheLocation: "sessionStorage",
       storeAuthStateInCookie: true
    },
    prompt: "select_account"
};

export const tokenRequest = {
  scopes: ["7c898514-c79b-48fd-98d6-8be05a17fa54/user"],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};


export const graphRequest = {
  scopes: ["openid user.read profile GroupMember.Read.All"], 
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};


