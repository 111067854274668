import React from 'react';
import {TableauContext} from './TableauAuth'

export const withTableau = (Component) =>{  
   
  class WithTableau extends React.Component{
      
      render(){
        return(        
            <TableauContext.Consumer>
                {value => <Component tableau={value} {...this.props}/>}
            </TableauContext.Consumer>     
       );
      }

  }


  return WithTableau          
    
};

