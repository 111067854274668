import React, {useEffect, useState,useContext} from "react";
import { MsalContext } from "@azure/msal-react";
import { FormGroup, H5, HTMLSelect } from "@blueprintjs/core";
import {getTokens} from '../auth/TokenManager'

export default function AdminOrgs() {


    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState("")
    const authContext = useContext(MsalContext);

    useEffect( () => {
        
        if(orgs.length===0){
            getTokens(authContext).then( (tokens) => {      
                fetch('https://cruxy-cockpit-api.azurewebsites.net/api/GetOrgs',{
                    method: 'GET',
                    headers: {
                    'Authorization': 'Bearer ' + tokens[0].accessToken,
                    'access-token': tokens[1].accessToken
                    }
                })
                .then(response => response.json())
                .then(data => {   
                        setOrgs(data)
                    })
                .catch( error => console.log("Could not get org data " + error.message)); 
             
          
            })     
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    },[orgs])



    return(
        <div>
           <H5>Admin Orgs</H5>
           <FormGroup label="Organisation:">
                <HTMLSelect value={selectedOrg} options={orgs} onChange={e => setSelectedOrg(e.currentTarget.value)}></HTMLSelect>
           </FormGroup>
           <H5>{selectedOrg}</H5>
        </div>
    )


}



